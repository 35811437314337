.App {
  height: 100vh;
  background-color: rgb(20, 20, 20);
  overflow: hidden;
  text-align: center;
  overflow-x: hidden;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  color: white;
  position: relative;
}

.authed-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}

.header-container{
  padding: 0;
}

.sub-text {
  font-size: 25px;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.flow-logo {
  width: 80px;
}

.logo-container {
  display: flex;
  gap: 15px;
  flex-direction: row;
  justify-content: center;
}

.image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-container > img {
  border-radius: 10px;
  padding: 15px;
}

.connect-wallet-button {
  color: white;
  background: -webkit-linear-gradient(55deg, rgba(2, 239, 139, 1) 0%, rgba(0, 254, 255, 1) 100%);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

.mint-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  margin-left: 10px;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-top: 45px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connected-container input[type="text"] {
  display: inline-block;
  padding: 10px;
  width: 50%;
  height: 60px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 10px;
  margin: 50px auto;
}

.connected-container button {
  height: 50px;
}

.button-glow {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.button-glow:before {
  content: "";
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: button-glow-animation 20s linear infinite;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.button-glow:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.logout-container {
  display: flex;
  justify-content: flex-end;
}

.logout-btn {
  margin: 15px 20px 0 0;
  background-color: #f94c66;
  color: white;
}

.gif-image {
  margin-top: 50px;
}

/* KeyFrames */
@-webkit-keyframes gradient-animation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
@-moz-keyframes gradient-animation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
@keyframes gradient-animation {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

/** Credits: https://codepen.io/kocsten/pen/rggjXp **/
@keyframes button-glow-animation {
  0% {
      background-position: 0 0;
  }
  50% {
      background-position: 400% 0;
  }
  100% {
      background-position: 0 0;
  }
}